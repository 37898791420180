import { CSSProperties } from 'react'

export const IconPlay = ({ className, style }: { className?: string; style?: CSSProperties }) => {
  return (
    <svg className={className} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.16675 10C4.16675 6.77836 6.77842 4.16669 10.0001 4.16669C13.2217 4.16669 15.8334 6.77836 15.8334 10C15.8334 13.2217 13.2217 15.8334 10.0001 15.8334C6.77842 15.8334 4.16675 13.2217 4.16675 10ZM12.5573 9.41167C13.0189 9.66811 13.0189 10.332 12.5573 10.5884L9.20516 12.4507C8.75653 12.6999 8.20521 12.3755 8.20521 11.8623V8.13776C8.20521 7.62455 8.75653 7.30014 9.20516 7.54938L12.5573 9.41167Z" fill="currentColor" />
    </svg>
  )
}

export const IconQuestion = ({ className, style }: { className?: string; style?: CSSProperties }) => {
  return (
    <svg className={className} style={style} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5 12C19.5 15.866 16.366 19 12.5 19C8.63401 19 5.5 15.866 5.5 12C5.5 8.13401 8.63401 5 12.5 5C16.366 5 19.5 8.13401 19.5 12ZM11.5719 9.3219C11.3156 9.57818 10.9001 9.57818 10.6438 9.3219C10.3876 9.06562 10.3876 8.6501 10.6438 8.39382C11.669 7.3687 13.331 7.3687 14.3562 8.39382C15.3813 9.41895 15.3813 11.081 14.3562 12.1061C14.0127 12.4496 13.5962 12.6787 13.1562 12.7919V13.0937C13.1562 13.4562 12.8624 13.75 12.5 13.75C12.1376 13.75 11.8438 13.4562 11.8438 13.0937V12.6562C11.8438 12.026 12.3425 11.631 12.7899 11.5303C13.0234 11.4778 13.2452 11.361 13.4281 11.1781C13.9406 10.6655 13.9406 9.83446 13.4281 9.3219C12.9155 8.80934 12.0845 8.80934 11.5719 9.3219ZM12.5 16.375C12.9832 16.375 13.375 15.9832 13.375 15.5C13.375 15.0168 12.9832 14.625 12.5 14.625C12.0168 14.625 11.625 15.0168 11.625 15.5C11.625 15.9832 12.0168 16.375 12.5 16.375Z" fill="currentColor" />
    </svg>
  )
}

export const IconTrophy = ({ className, style }: { className?: string; style?: CSSProperties }) => {
  return (
    <svg className={className} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 4C10.3747 4 8.77982 4.13225 7.22562 4.3867C6.9033 4.43947 6.66667 4.71799 6.66667 5.04461V5.3882C5.9319 5.5276 5.2071 5.69526 4.49346 5.89002C4.21174 5.96691 4.01285 6.21819 4.00272 6.51003C4.00091 6.56205 4 6.61427 4 6.66667C4 8.9732 5.75685 10.8692 8.00565 11.0897C8.68953 11.8621 9.59501 12.4354 10.6236 12.7094C10.5381 13.4084 10.3279 14.0683 10.016 14.6667H9.77778C9.0414 14.6667 8.44444 15.2636 8.44444 16V18.2222H7.77778C7.16413 18.2222 6.66667 18.7197 6.66667 19.3333C6.66667 19.7015 6.96514 20 7.33333 20H16.6667C17.0349 20 17.3333 19.7015 17.3333 19.3333C17.3333 18.7197 16.8359 18.2222 16.2222 18.2222H15.5556V16C15.5556 15.2636 14.9586 14.6667 14.2222 14.6667H13.984C13.6721 14.0683 13.4619 13.4084 13.3764 12.7094C14.405 12.4354 15.3105 11.8621 15.9943 11.0897C18.2432 10.8692 20 8.9732 20 6.66667C20 6.61425 19.9991 6.56204 19.9973 6.51003C19.9871 6.21819 19.7883 5.96691 19.5065 5.89002C18.7929 5.69526 18.0681 5.5276 17.3333 5.3882V5.04461C17.3333 4.71799 17.0967 4.43947 16.7744 4.3867C15.2202 4.13225 13.6253 4 12 4ZM5.35577 7.04217C5.78868 6.93312 6.22574 6.83447 6.66667 6.74652V7.55556C6.66667 8.21288 6.78581 8.84289 7.00361 9.42479C6.11615 8.96019 5.4806 8.07971 5.35577 7.04217ZM18.6442 7.04217C18.5194 8.07971 17.8838 8.96019 16.9964 9.42479C17.2142 8.84289 17.3333 8.21288 17.3333 7.55556V6.74652C17.7743 6.83447 18.2113 6.93312 18.6442 7.04217Z" fill="currentColor" />
    </svg>
  )
}

export const IconCaret = ({ className, style }: { className?: string; style?: CSSProperties }) => {
  return (
    <svg className={className} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4023 11.3132C14.5976 11.5084 14.5976 11.825 14.4023 12.0203L13.9309 12.4917C13.7356 12.6869 13.4191 12.6869 13.2238 12.4917L10.3535 9.62136C10.1582 9.42609 9.84165 9.42609 9.64638 9.62136L6.77608 12.4917C6.58082 12.6869 6.26424 12.6869 6.06897 12.4917L5.59757 12.0203C5.40231 11.825 5.40231 11.5084 5.59757 11.3131L9.64638 7.26433C9.84165 7.06907 10.1582 7.06907 10.3535 7.26433L14.4023 11.3132Z" fill="currentColor" />
    </svg>
  )
}

export const IconArrowTopRight = ({ className, style }: { className?: string; style?: CSSProperties }) => {
  return (
    <svg className={className} style={style} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.93527 8.33468C7.82564 8.45296 7.7965 8.62494 7.86103 8.77273C7.92556 8.92053 8.07151 9.01606 8.23277 9.01606L11.8933 9.01606C12.6003 9.01606 13.1821 9.01496 13.6379 9.01275L7.91572 14.909C7.83947 14.9876 7.79822 15.0936 7.80134 15.2031C7.80446 15.3125 7.85168 15.416 7.93228 15.4901L8.73706 16.23C8.89907 16.3789 9.1503 16.3715 9.30325 16.2133L14.9955 10.3248C14.9934 10.7764 14.9923 11.3474 14.9923 12.0373L14.9923 15.9314C14.9923 16.0935 15.0888 16.24 15.2377 16.304C15.3866 16.3681 15.5593 16.3373 15.677 16.2259L16.6635 15.2913C16.7443 15.2147 16.7901 15.1082 16.7901 14.9968L16.7901 7.54602C16.7901 7.322 16.6085 7.14038 16.3845 7.14039L9.21929 7.14038C9.10636 7.14038 8.99854 7.18746 8.92178 7.27029L7.93527 8.33468ZM9.21924 7.54608L9.21929 7.54602L16.3845 7.54602L16.3845 14.9968L16.3844 14.9968L16.3844 7.54608L9.21924 7.54608Z" fill="currentColor" />
    </svg>
  )
}